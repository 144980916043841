@import "./Animations.scss";
@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700;800&display=swap');
@font-face {
  font-family: "Okaeri Decorative";
  src: url('./assets/fonts/okaeri-decorative.ttf') format('truetype');
}

@font-face {
  font-family: "Vayu Sans";
  src: url('./assets/fonts/VayuSans-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: "Vayu Sans";
  src: url('./assets/fonts/VayuSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: "Vayu Sans";
  src: url('./assets/fonts/VayuItalic-Regular.ttf') format('truetype');
  font-style: italic;
}

@font-face {
  font-family: "Vayu Sans";
  src: url('./assets/fonts/VayuSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Vayu Sans";
  src: url('./assets/fonts/VayuSans-ExtraBold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

:root {
  --white: #FFFFFF;
  --grey: #C4C4C4;
  --black: #000000;
  --transparentBlack: #000000e3;
  --borderColor: #151515;
  --info: skyblue;
  --secBlack: #222222;
  --red: #FF3E48;
  --secRed: #9f2a30;
  --green: #36B34B;
  --primaryColor: var(--black);
  --secondaryColor: var(--white);
  --accentColor: #FFB73D;
  --secAccentColor: #F2EA12;

  --bgColor: var(--black);

  --font: 'Vayu Sans',
    sans-serif;
  --titleFont: 'Orbitron',
    sans-serif;
  --defBoxShadow: 0px 1px 3px 3px #c5c5c5;
  --defRadius: 5px;

  --pagePading: 0px 10vw;
  --fullPagePading: 200px 10vw;
  --maxPageWidth: 1560px;
  --fontSize: 1.2em;
}


@mixin defTxt {
  font-family: var(--font);
  color: var(--white);
  font-weight: normal;
}

@mixin pointerItem {
  cursor: pointer;
  outline: none;
}

@mixin pageWrapper {
  display: flex;
  flex-flow: column;
  margin: 0px auto;

  padding: var(--pagePading);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 400px;
  max-width: var(--maxPageWidth);
}

@mixin scaleOnHover {
  @include pointerItem();
  -webkit-transition: transform 1s;
  -moz-transition: transform 1s;
  transition: transform 1s;

  &:hover {
    transform: scale(1.2) !important;
    -ms-transform: scale(1.2) !important;
    -webkit-transform: scale(1.2) !important;
    -moz-transform: scale(1.2) !important;
  }
}

h1,
h2,
h3,
h4,
h6,
p,
b,
span {
  @include defTxt();
  margin: 0px;
  padding: 0px;
}

a,
ul {
  @include defTxt();
  text-decoration: none;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-30 {
  padding-left: 30px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}


.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}
.mb-100 {
  margin-bottom: 100px !important;
}

body {
  background-color: var(--bgColor);
  margin: 0px;
  padding: 0px;
  font-family: var(--font);
}

.success-txt {
  @include defTxt();
  color: var(--green);
}

.info-txt {
  @include defTxt();
  color: var(--info);
}
.error-txt {
  @include defTxt();
  color: var(--red);
}

.success-txt,
.info-txt,
.error-txt {
  font-size: 1.2em;
}

.def-link {
  @include defTxt();
  @include pointerItem();
  color: var(--accentColor);

  &:hover {
    color: var(--accentColor)
  }
}


// BUTTON START ---------------------------/


.def-btn,
.outline-btn,
.primary-btn,
.accent-btn,
.black-btn {
  @include defTxt();
  @include pointerItem();
  padding: 5px;
  border-radius: var(--defRadius);
  border: none;
  text-align: center;
  display: flex;
  grid-gap: 5px;
  align-items: center;

  &>svg {
    font-size: inherit;
  }

  &:hover,
  &.active,
  &:active {
    background-color: var(--accentColor);
    color: var(--black);
    font-weight: bold;
  }
}

.outline-btn {
  background-color: var(--secBlack);
  border: 2px solid var(--white);
}

.primary-btn {
  color: var(--white);
  border: 2px solid var(--secRed);
  background-color: var(--red);
}

.accent-btn {
  color: var(--black);
  background-color: var(--accentColor);
  &:hover,
  &:active {
    background-color: var(--secAccentColor);
    color: var(--black);
    font-weight: bold;
  }
}

.black-btn {
  color: var(--grey);
  background-color: var(--secBlack);
}


button:disabled,
button[disabled] {
  cursor: wait !important;
  background-color: #999999e0 !important;
  color: var(--secBlack) !important;
  outline: none;
  border: 2px solid var(--black);

  &:hover {
    cursor: wait !important;
    background-color: #999999e0 !important;
    color: var(--secBlack) !important;
    border: 2px solid var(--black);
  }
}


// BUTTON END ---------------------------/



// PAGE START ---------------------------/
.page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 150px;

  img {
    height: 100%;
    max-height: inherit;
    margin: 0px auto;
  }
}

// PAGE END ---------------------------/

// TEXT START ---------------------------/
.page-title {
  @include defTxt();
  font-family: var(--titleFont);
  font-size: 4em;
  text-align: left;
}
.page-sub-title {
  @include defTxt();
  font-size: 2em;
  text-align: left;
}

.def-title {
  @include defTxt();
  font-size: 1.5em;
  font-weight: 900;
  text-align: left;

}

.def-sub-title {
  @include defTxt();
  color: var(--grey);
  font-size: 1.5em;
  text-align: left;
}
// TEXT END ---------------------------/


// FORM START ---------------------------/

.def-input {
  @include defTxt();
  outline: none;
  background-color: var(--secBlack);
  padding: 10px;
  border: 2px solid var(--borderColor);
  border-radius: var(--defRadius);
}

label {
  @include defTxt();
  display: flex;
  flex-flow: row;
  box-sizing: border-box;
  grid-gap: 5px;
  align-items: center;

  &>small {
    color: var(--grey);
  }

}

// FORM END ---------------------------/

// APP START ------//
.scroll-area {
  position: relative;
}
.App {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  position: relative;
}

.video-bg {
  object-fit: cover;
  position: fixed;
  z-index: -3;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

// APP END   ------//



// NAVBAR SECTION START ------//

.account-display {
  @include defTxt();
  height: 35px;
  width: max-content;
  display: grid;
  grid-template-columns: 35px auto;
  grid-gap: 10px;
  align-items: center;
  background-color: var(--red);
  border-radius: 20px;

  font-weight: bold;
  align-items: center;
  box-sizing: border-box;
  // grid-template-rows: 35px;
  border: 3px solid var(--secRed);
  box-sizing: content-box;

  justify-content: flex-end;
  &>div>div {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border: 3px solid var(--accentColor);
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
  }
}

.navbar {
  padding: var(--pagePading);
  display: grid;
  grid-template-columns: 100px auto 300px;
  align-items: center;
  box-sizing: border-box;
  position: sticky;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  background: linear-gradient(180deg, var(--bgColor), 80%, transparent);

  .logo {
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
  }



  .nav-links-wrapper {
    display: flex;
    flex-flow: row;
    grid-gap: 20px;
    height: inherit;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    justify-content: center;

    .nav-link-item {
      @include pointerItem();
      min-width: 70px;
      height: 100%;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 0px 2.5px;
      align-items: center;

      &:hover {
        color: var(--accentColor);
        font-weight: bold !important;
        border-bottom: 3px solid var(--accentColor);
      }

      .nav-link {
        @include defTxt();
        font-family: var(--titleFont);
        scroll-behavior: smooth;
        font-size: 1.2em;
        &:hover {
          color: var(--accentColor);
          font-weight: bold;
        }
      }
    }



  }

  .nav-connect-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    flex-flow: row;
    grid-gap: 15px;

    .nav-menu-link>svg,
    svg {
      @include pointerItem();
      font-size: 1.5em;
      color: var(--white);

      &:hover {
        color: var(--accentColor)
      }
    }
    img {
      height: 25px;
      width: 25px;
    }
  }

}

.mobile-nav {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 100%;
  grid-template-rows: 70px auto 70px;
  grid-gap: 10px;
  padding: 0px 5vw;
  margin: 0px;

  position: sticky;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;

  background-color: var(--bgColor);
  z-index: 10;
  overflow: hidden;

  .nav-link,
  .nav-menu-link>svg {
    @include defTxt();
    @include pointerItem();
    font-size: 2.5em;
    color: var(--white);

    &:hover {
      color: var(--accentColor)
    }
  }

  .mobile-header-nav {
    padding: 0px;
    display: grid;
    grid-template-columns: 100px auto;
    align-items: center;
    box-sizing: border-box;
    height: 70px;
    width: 100%;

    .logo {
      max-height: 60px;
      max-width: 60px;
      border-radius: 50%;
    }

    .mobile-nav-left {
      display: flex;
      flex-flow: row;
      grid-gap: 10px;
      justify-content: flex-end;
      align-items: center;
    }

  }

  .mobile-nav-links-wrapper {
    display: flex;
    flex-flow: column;
    grid-gap: 5px;
    box-sizing: border-box;
    width: 100%;
    min-height: 250px;

    .nav-link-item {
      display: flex;
      height: 70px;
      align-items: center;
      border-bottom: 2px solid var(--secBlack);

      .nav-link {
        font-size: 2em;
      }
    }
  }

  .mobile-nav-social-links {
    display: flex;
    flex-flow: row;
    grid-gap: 15px;
    height: 70px;
    align-items: center;
    justify-content: flex-start;
  }
}

// NAVBAR SECTION START ------//



// HOME SECTION START ------//
.home-section {
  @include pageWrapper();
  padding: var(--pagePading);
  // padding: 0px;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100% !important;
  display: flex;
  justify-content: center;
  position: relative;
  .mint-section-wrapper {
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    max-width: 500px;
    text-align: left;

    .landing-logo {
      max-width: 90%;
    }

    .landing-txt {
      @include defTxt();
      font-size: 1.5em;
      color: var(--white);
      font-weight: bold
    }

    .call-to-action {
      max-width: 200px !important;
      padding: 15px;
      font-size: 100%;
      font-family: var(--titleFont);
      &:hover {
        font-weight: bold;
      }
    }

    .mint-opt-wrapper {
      .mint-opt-header-wrapper,
      .mint-opt-body-wrapper {
        min-height: 70px;
      }

      .mint-opt-header-wrapper {
        display: flex;
        flex-flow: row;
        box-sizing: border-box;

        .mint-opt-tab-item {
          @include defTxt();
          color: var(--white);
          font-weight: normal;
          columns: var(--white);
          min-width: 100px;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.1em;
          font-weight: bold;
          grid-gap: 10px;
          .network-icon {
            width: 15px;
          }

          &.active {
            background-color: #1c1c1c;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;

          }
        }
      }

      .mint-opt-body-wrapper {
        padding: 10px;
        background-color: var(--secBlack);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        display: grid;
        grid-template-columns: 160px auto;
        grid-gap: 20px;

        .left-wrapper {
          @include defTxt();
          font-size: 1.5em;
          font-weight: bold;
          display: grid;
          grid-template-columns: 50px auto 50px;
          align-items: center;
          text-align: center;

          button {
            height: 50px;
            text-align: center;
            font-size: 2em;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .right-wrapper {
          display: flex;
          flex-flow: column;
          grid-gap: 5px;
          align-items: flex-start;
          box-sizing: border-box;

          h1,
          h3 {
            @include defTxt();
            color: var(--white);
            font-weight: bold;
          }

          h1 {
            font-size: 1.5em;
            font-weight: bold;
          }

          h3 {
            font-size: 1.2em;
            color: var(--accentColor);
          }
        }

      }
    }
  }
.home-carousel-wrapper {
  max-width: 45vw;
  position: absolute;
  right: 0vw;
  z-index: -1;


  &::before {
    @include defTxt();
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to right, var(--black) 5%, #25252500 20%, #ffffff00 67%, var(--bgColor) 100%);
    z-index: 10;
  }

  .alice-carousel__stage-item {
    width: 300px !important;
    height: 100% !important;
    margin-right: 30px;
    margin-bottom: 15px;
  }

}

.carousel-nft-img {
  height: 300px;
  width: 300px;
  width: 100%;
  max-width: 300px;
  max-height: 350px;
  // border: 5px;
  margin-right: 10px;
}

}

// LANDING SECTION END   ------//

// FEEDBACK SECTION END   ------//

.feedback-section {
  @include pageWrapper();
  min-height: 100%;
  padding: var(--pagePading);

  flex-flow: column;
  text-align: center;
}

// FEEDBACK SECTION END   ------//

// GALLERY SECTION START ------//
.gallery-section {
  @include pageWrapper();
  padding-bottom: 10vh;
  padding-top: 10vh;
  position: relative;

  .gallery-filter-wrapper {
.main-filter-wrapper,
.sub-filter-wrapper {
      display: flex;
      flex-flow: row;
      box-sizing: border-box;
      grid-gap: 20px;
    }

    .primary-btn {
      min-width: 50px;
      padding: 5px 20px;
      font-size: 1.2em;
      // border-radius: 20px;
      }
      }

      .gallery-placeholder-wrapper {
        display: flex;
        padding: 50px 0px;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        min-height: 500px;

        h2 {
          font-size: 3em;
          color: var(--accentColor);
        }

        p {
          font-size: 1.4em;
        }

        a {
          font-weight: bold;
          padding: 5px 80px;
    }
  }

  .gallery-body-wrapper {
    display: flex;
    flex-flow: column;
    grid-gap: 30px;
    box-sizing: border-box;



    .gallery-list-wrapper {
      display: grid;
      box-sizing: border-box;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 30px;

      .nft-item {
        min-height: 300px;
        min-width: 200px;

        .nft-img-wrapper {
          display: flex;
          width: 100%;
          min-height: 250px;

          img {
            width: 100%;
            border-radius: var(--defRadius);
          }
        }

        .nft-detail-wrapper {
          display: flex;
          flex-flow: column;
          box-sizing: border-box;
          justify-content: center;
          text-align: left;
          padding: 10px 0px;

          h1,
          h3 {
            @include defTxt();
          }

          h1 {
            color: var(--grey);
            font-size: 1em;
          }
        }
        }
        }
        }

  .gallery-paginate-wrapper {
    display: flex;
    flex-flow: row;
    grid-gap: 5px;
    justify-content: flex-end;

    ul {
      list-style: none;
      display: flex;
      box-sizing: border-box;
      grid-gap: 10px;
      font-size: 1.2em;

      li {
        @include pointerItem();

        padding: 5px 10px;
        background-color: var(--red);
        border-radius: 5px;
        border: 3px solid var(--secRed);

        &>a {
          color: var(--white);
        }

        &.selected,
        &:hover {
          background-color: var(--accentColor);
          border: 3px solid var(--secAccentColor);

          &>a {
            color: var(--black) !important;
            font-weight: bold;
          }

        }
        }
        }
        }
        }

.lazy-load-image-background {
  min-height: 210px;
}
// GALLERY SECTION END   ------//

// TERMS SECTION START ------//
.terms-section {
  @include pageWrapper();
  padding-bottom: 10vh;
  padding-top: 10vh;
  position: relative;

  .terms-list-wrapper {
    .page-title {
      @include defTxt();
      font-size: 2em;
      font-weight: bold;
    }

    b {
      font-weight: bold;
      font-size: 1.2em
    }

    ;
  }
}

// TERMS SECTION START ------//

// ABOUT SECTION START ------//
.about-section {
  @include pageWrapper();
  padding: var(--fullPagePading);
  min-height: 100vh;
  position: relative;
  .about-component-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-gap: 50px;
    box-sizing: border-box;

    .about-left-wrapper {
      width: 100%;

      .about-img {
        width: 100%;
        height: 100%;
      }


    }

    .about-right-wrapper {
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;

      p {
        @include defTxt();

        font-size: 1.5em;
        color: var(--grey);

        &:nth-child(1) {
          position: relative;
          font-weight: bold;
          font-size: 2em;
          color: var(--white);

          &::before {
            @include defTxt();
            content: '“';
            position: absolute;
            top: -50px;
            left: 0px;
            font-size: 2em;
            line-height: 100px;
            font-weight: 900;
          }

          &::after {
            @include defTxt();
            content: '”';
            position: absolute;
            font-size: 2em;
            line-height: 100px;
            font-weight: 900;
          }
        }
      }
    }
  }
}

// ABOUT SECTION END   ------//

// ROADMAP SECTION START ------//
.roadmap-section,
.gallery-section {

  .roadmap-header-wrapper,
  .gallery-header-wrapper {
    display: grid;
    grid-template-columns: 350px auto;
    grid-gap: 30px;
    align-items: center;
    box-sizing: border-box;

    .right-wrapper {
      p {
        @include defTxt();
        color: var(--white);
        font-size: 1.5em;
        max-width: 500px;
      }
    }
  }
}

.roadmap-section {
  @include pageWrapper();
  min-height: 70vh;
  position: relative;

  .grid-span-2 {
    grid-column: span 2;
  }

  .roadmap-grid-wrapper {
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 200px 250px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    position: relative;

    .comming-soon-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: var(--black);
      display: grid;
      place-content: center;
      opacity: 0.9;
      z-index: 100;

      h1 {
        opacity: 1 !important;
      }
    }

    .roadmap-item {
      @include pointerItem();
      background-position: top left;
      background-repeat: no-repeat;
      background-size: 100%;
      display: flex;
      position: relative;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      text-align: left;
      padding: 0px;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        background-size: contain;

      }

      h1,
      b {
        @include defTxt();
        font-family: var(--titleFont);
        font-size: 1.2em;
        font-weight: bold;
        position: absolute;
        line-height: 30px;


        &>b {
          font-size: 2em;
        }

      }

      &:nth-child(1),
      &:nth-child(5) {
        h1 {
          left: 15px;
          bottom: 30px;
        }
      }

      &:nth-child(2) {
        h1 {
          left: 30px;
          bottom: 10%;
        }
      }

      &:nth-child(3) {
        h1 {
          left: 30%;
          top: 60%;

        }
      }

      &:nth-child(4),
      &:nth-child(7) {
        h1 {
          left: 30px;
          top: 15px;
        }
      }

      &:nth-child(4) {

        h1,
        b {
          color: #54144f;
        }
      }

      &:nth-child(7) {

        h1,
        b {
          color: #414b81;
        }
      }

      &:nth-child(6) {
        h1 {
          left: 25px;
          top: 40%;
          color: #205878;

          &>b {
            color: #205878;
          }
        }
        }

      &:nth-child(7) {
        color: #87827a;
      }
    }
  }
}

// ROADMAP SECTION END ------//


// FAQ SECTION START ------//


.faq-section {
  @include pageWrapper();
  padding: var(--fullPagePading) !important;
  min-height: 100vh;
  position: relative;
  .faq-item {
    @include pointerItem();
    display: flex;
    box-sizing: border-box;
    flex-flow: column;
    width: 100%;
    padding: 10px 15px;
    background-color: var(--red);
    border-radius: 5px;

    .faq-header-wrapper {

      display: grid;
      align-items: center;
      grid-template-columns: auto 50px;
      h3 {
        @include defTxt();
        font-family: var(--titleFont);
        font-size: 1.3em;
        font-weight: bold;
        color: var(--white);
      }

      .action-wrapper {
        @include defTxt();
        font-weight: bold;
        font-size: 2em
      }
    }

    .faq-details-wrapper {
      p {
        font-weight: bold;
        font-size: 1.2em;
        color: var(--black);
      }
    }

    &.active,
    &:hover {
      background-color: var(--secAccentColor);

      h3 {
        color: var(--red);
        font-size: 1.4em;
      }

      .action-wrapper {
        color: var(--red);
        font-weight: bold;
        font-size: 3em;
      }
    }

  }

}

// FAQ SECTION END   ------//
// TEAM SECTION START -------------------//
.team-section {
  @include pageWrapper();
  // background-color: var(--bgColor);
  padding: var(--fullPagePading);

  .page-section-wrapper {
    display: flex;
    flex-flow: column;
    box-sizing: border-box;
    padding: 10vh 0vw;
    width: 100%;
  }

  .page-header-img {
    max-width: 400px;
    margin: 0px auto;
  }

  .team-carousel-wrapper {

    max-width: var(--maxPageWidth);
    margin: 0px auto;

    width: 100%;

    .team-item {
      @include pointerItem();
      background-color: transparent;
      width: 100%;
      min-height: 350px;
      padding: 5px;
      display: flex;
      flex-flow: column;
      box-sizing: border-box;
      text-align: center;
      position: relative;
      max-width: 400px;

      .team-img,
      .team-title {
        z-index: 5;
      }

      .team-img {
        max-width: 200px;
        border-radius: 50%;
        margin: 0px auto;
        margin-top: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border: 4px solid var(--secRed);
      }

      .team-title {
        font-family: var(--titleFont);
        font-weight: bold;
        color: var(--teal);
        font-size: 2em;
      }

      .team-sub-title {
        @include defTxt();
        font-size: 1.3em;
        color: var(--secRed);
        font-weight: bold;
      }

      .def-txt {
        margin: 0px auto;
        font-size: 1.2em;
        max-width: 200px;
      }
    }

  }
// CAROUSEL OVERIDE START --------//
.alice-carousel__dots {
  margin: 0px;
  padding: 10px 0px !important;
}

.alice-carousel__dots-item {
  background-color: var(--accentColor) !important;

  &.__active {
    background-color: var(--red) !important;
  }
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  color: var(--accentColor) !important;
  font-size: 2em !important;
  font-weight: bold;

  position: absolute;
  bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--secRed);
  padding: 0px !important;
  border: 4px solid var(--secRed);

  &>span {
    color: var(--accentColor);
    font-weight: bold;
  }



  &:hover {
    color: darkred;
    border: 4px solid var(--secRed);
    background-color: var(--accentColor);

    &>span {
      color: var(--secRed);
      font-weight: bold;
    }

  }
}

.alice-carousel__prev-btn-item {
  left: 5vw;
}

.alice-carousel__next-btn-item {
  right: 5vw;
}

// CAROUSEL OVERIDE END   --------//

}

// TEAM SECTION END -------------------//

// FOOTER SECTION START ------//
footer {
  padding: 20px 0px 0px 0px;
  min-height: 500px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--transparentBlack);
    z-index: -2;

  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(./assets/images/footer-bg.svg);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;


    -webkit-animation: fadeIn 5s;
    /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeIn 5s;
    /* Firefox < 16 */
    -ms-animation: fadeIn 5s;
    /* Internet Explorer */
    -o-animation: fadeIn 5s;
    /* Opera < 12.1 */
    animation: fadeIn 5s;
  }

  .def-txt {
    font-size: var(--fontSize);
    line-height: 18px;
    color: var(--white);
  }
  .footer-top-wrapper {
    padding: var(--pagePading);
    display: flex;
    flex-flow: column;
    grid-gap: 30px;
    align-items: center;
    min-height: 400px;
    justify-content: flex-start;
    max-width: 500px;
    margin: 0px auto;


    .footer-logo {
      max-width: 100%;
    }

    .footer-txt {
      @include defTxt();
      font-size: 1.2em;
      text-align: center;
    }
    .footer-nav-links-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-wrap: wrap !important;
      flex-flow: row;
      grid-gap: 15px;

      .footer-link {
        @include pointerItem();
        @include defTxt();
        color: var(--black);
        background-color: var(--accentColor);
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 1.2em;
        font-weight: bold;

        &:active,
        &:hover {
          color: var(--red);
          background-color: var(--secAccentColor);
          border: 3px solid var(--secRed)
        }
      }
    }
    .footer-social-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      flex-flow: column;
      grid-gap: 0px;


      .footer-links-wrapper {
        display: flex;
        flex-flow: row;
        box-sizing: border-box;
        grid-gap: 15px;

        .footer-link {
          @include pointerItem();
          font-size: 2em;

          &:hover {
            color: var(--accentColor);
          }
        }
      }
    }
  }


  .copyright-wrapper {
    @include defTxt();
    color: var(--grey);
    padding: var(--pagePading);
    width: 100%;
    align-items: center;
    height: 50px;
    background-color: var(--secBlack);
    text-align: center;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }
}

// FOOTER SECTION END   ------//


// MODAL SECTION START   ------//
.ReactModal__Content {
  padding: 0px !important;
}

.ReactModal__Overlay {
  background-color: #000000bf !important;

  .modal-body-top {
    position: relative;
    padding: 30px;
    min-height: 250px;

    .img-shadow {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -2;
    }

    .img-shadow,
    .shadow {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .shadow {
      background-color: #00000061 !important;
      z-index: -1;
    }
  }

  .modal-header,
  .modal-body {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    z-index: 10;
  }

  .modal-header {
    display: grid;
    grid-template-columns: auto 50px;
    grid-gap: 20px;
    box-sizing: border-box;
    align-items: center;


    .modal-title {
      @include defTxt();
      font-size: 2em;
      font-family: var(--titleFont);
      font-weight: bold;
    }

    .close-btn {
      @include pointerItem();
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      color: var(--white);
      border-radius: 50%;
      background-color: var(--secBlack);
      justify-content: center;
      border: 1px solid var(--white);
      outline: none;
    }
  }

  .modal-body {
    p {
      font-size: 1.3em;
      text-align: left;
    }
  }
}

// MODAL SECTION END   ------//

// LOADER SECTION START   ------//

.loader-wrapper {
  display: grid;
  place-content: center;
  min-height: 450px;

  .loader-spinner-wrapper {
    display: flex;
    justify-content: center;
    margin: 5vh 0px;
    box-sizing: border-box;
  }

  .loader-txt {
    @include defTxt();
    color: var(--grey);
  }

}

.sk-chase {
  width: 40px;
  height: 40px;
  position: relative;
  animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {

  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1.0);
  }
}

// LOADER SECTION END    ------//

.sm-hidden {
  // visibility: visible !important;
  display: inline-flex !important;
}

.lg-hidden {
  // visibility: hidden !important;
  display: none !important;
}



@media screen and (min-width: 981px) and (max-width: 1280px) {
  .roadmap-item {
    h1 {
      font-size: 1em !important;
    }

    &:nth-child(1) {
      h1 {
        bottom: 20% !important;
      }
    }

    &:nth-child(2),
    &:nth-child(5) {
      h1 {
        bottom: 45% !important;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 980px) {
  .about-section {
    .about-component-wrapper {
      grid-template-columns: 100% !important;
    }
  }
  .skull {
    opacity: 0.2;
  }

  .greaser {
    z-index: -1;
    opacity: 0.7;
  }
  .roadmap-grid-wrapper {
    grid-template-rows: repeat(4, 250px) !important;
    grid-template-columns: repeat(2, 50%) !important;

    .roadmap-item {
      background-size: contain !important;

      &:nth-child(4) {
        grid-row-start: 3;
      }
    }
    }
}



@media only screen and (max-width: 767px) {

  /* phones */

  :root {
    --pagePading: 5vw;
    --fullPagePading: 100px 5vw;
    --defFontSize: 1em;
  }

  .sm-hidden {
    // visibility: hidden !important;
    display: none !important;
  }

  .lg-hidden {
    // visibility: visible !important;
    display: inline-flex !important;
  }


  .navbar {
    grid-template-columns: 100px auto;
    padding: 0px 5vw !important;
  }

  .home-section {

    .mint-opt-body-wrapper .right-wrapper {
      font-size: 1em !important;
    }
.home-carousel-wrapper {
  max-width: 100vw !important;

  &::after {
    @include defTxt();
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #000000bf;
    z-index: 5;
  }
}
  }

  .about-section,
  .faq-section {
    min-height: auto !important;
  }

  .about-section {
    .about-component-wrapper {
      grid-template-columns: 100% !important;
    }
  }

  .roadmap-section,
  .gallery-section {

    .roadmap-header-wrapper,
    .gallery-header-wrapper {
      grid-template-columns: 100% !important;
    }
  }

  .roadmap-section {


    .roadmap-grid-wrapper {

      grid-template-rows: repeat(4, 150px) !important;
      grid-template-columns: repeat(2, 50%) !important;
      grid-gap: 30px 10px;

      .roadmap-item {
        background-size: contain !important;

        h1 {
          font-size: 1em !important;
        }

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(5) {
          h1 {
            bottom: 20% !important;
          }
        }
        &:nth-child(4) {
          grid-row-start: 3;
        }
      }
    }
  }

  .gallery-section {

    .gallery-component-wrapper {
      grid-template-columns: 100% !important;
    }
    .gallery-body-wrapper {
      .gallery-list-wrapper {
        justify-content: center !important;
      }
    }

  }

  footer {
    .copyright-wrapper {
      justify-content: flex-start !important;
      text-align: left !important;
    }

    .footer-top-wrapper {
      .footer-logo {
        max-width: 90% !important;
      }
    }
  }


}


@media only screen and (max-width: 400px) {}