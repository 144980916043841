/**
 * ----------------------------------------
 * Hide Text Animation
 * ----------------------------------------
 */
@mixin hideText {
  -moz-animation: cssAnimation 0s ease-in 5s forwards;
  /* Firefox */
  -webkit-animation: cssAnimation 0s ease-in 5s forwards;
  /* Safari and Chrome */
  -o-animation: cssAnimation 0s ease-in 5s forwards;
  /* Opera */
  animation: cssAnimation 0s ease-in 5s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

/**
 * ----------------------------------------
 * Fade In
 * ----------------------------------------
 */

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * Hover animation 
 * ----------------------------------------
 */

 @keyframes float {
  0% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }

  50% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
  }

  100% {
    -webkit-transform: translatey(0px);
    transform: translatey(0px);
  }
}

 @-webkit-keyframes float {
   0% {
     -webkit-transform: translatey(0px);
     transform: translatey(0px);
   }

   50% {
     -webkit-transform: translatey(-20px);
     transform: translatey(-20px);
   }

   100% {
     -webkit-transform: translatey(0px);
     transform: translatey(0px);
   }
 }

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {

  0%,
  100% {
    opacity: 1;
  }

  31.98% {
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  32.8% {
    opacity: 0;
  }

  32.82% {
    opacity: 1;
  }

  34.98% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  35.7% {
    opacity: 0;
  }

  35.72% {
    opacity: 1;
  }

  36.98% {
    opacity: 1;
  }

  37% {
    opacity: 0;
  }

  37.6% {
    opacity: 0;
  }

  37.62% {
    opacity: 1;
  }

  67.98% {
    opacity: 1;
  }

  68% {
    opacity: 0;
  }

  68.4% {
    opacity: 0;
  }

  68.42% {
    opacity: 1;
  }

  95.98% {
    opacity: 1;
  }

  96% {
    opacity: 0;
  }

  96.7% {
    opacity: 0;
  }

  96.72% {
    opacity: 1;
  }

  98.98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  99.6% {
    opacity: 0;
  }

  99.62% {
    opacity: 1;
  }
}

@keyframes flicker-4 {

  0%,
  100% {
    opacity: 1;
  }

  31.98% {
    opacity: 1;
  }

  32% {
    opacity: 0;
  }

  32.8% {
    opacity: 0;
  }

  32.82% {
    opacity: 1;
  }

  34.98% {
    opacity: 1;
  }

  35% {
    opacity: 0;
  }

  35.7% {
    opacity: 0;
  }

  35.72% {
    opacity: 1;
  }

  36.98% {
    opacity: 1;
  }

  37% {
    opacity: 0;
  }

  37.6% {
    opacity: 0;
  }

  37.62% {
    opacity: 1;

  }

  67.98% {
    opacity: 1;
  }

  68% {
    opacity: 0;
  }

  68.4% {
    opacity: 0;
  }

  68.42% {
    opacity: 1;
  }

  95.98% {
    opacity: 1;
  }

  96% {
    opacity: 0;
  }

  96.7% {
    opacity: 0;
  }

  96.72% {
    opacity: 1;
  }

  98.98% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  99.6% {
    opacity: 0;
  }

  99.62% {
    opacity: 1;
  }
}